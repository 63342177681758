<template>
  <div
    id="source"
    draggable="true"
    @mousedown="dragStart"
    @mousemove="dragMove"
    @mouseup="dragEnd"
    @dragstart="dragStart"
    @dragmove="dragMove"
    @dragend="dragEnd"
    @touchstart="dragStart"
    @touchmove="dragMove"
    @touchend="dragEnd"
    style="z-index: 10; display: flex; align-items: center; position: fixed;"
    :style="'top:' + top + ';left:' + left + ';'"
  >
    <img src="@/assets/hippo-home/cust-service.png" style="width:130px;" />
  </div>
</template>

<script setup>
import {ref} from "vue";

let isDragging = false;
let diffX = 0;
let diffY = 0;
let firstX = 0;
let firstY = 0;
let top = ref("30%");
let left = ref("70%");
if(window.innerWidth > window.innerHeight) {
  top = ref('38%');
} else {
  top = ref('15%');
}

const jumpToLiveClass = () => {
  window.open('https://readapi.bluebirdabc.com/lessonplayer/index.html?userId=1111&pid=&lessonId=206&auto=true');
};
const dragStart = (e) => {
  e.preventDefault();
  isDragging = true;
  let ele = document.getElementById("source");
  if (e.type == "touchstart") {
    let _e = e.changedTouches[0];
    diffX = _e.clientX - ele.offsetLeft;
    diffY = _e.clientY - ele.offsetTop;
    firstX = _e.clientX;
    firstY = _e.clientY;
  } else if (e.type == "mousedown") {
    diffX = e.clientX - ele.offsetLeft;
    diffY = e.clientY - ele.offsetTop;
    firstX = e.clientX;
    firstY = e.clientY;
  }
};

const dragMove = (e) => {
  if (!isDragging) return;
  e.preventDefault();
  if (e.type == "touchmove") {
    let _e = e.changedTouches[0];
    left.value = _e.clientX - diffX + "px";
    top.value = _e.clientY - diffY + "px";
  } else if (e.type == "mousemove") {
    left.value = e.clientX - diffX + "px";
    top.value = e.clientY - diffY + "px";
  }
};
const dragEnd = (e) => {
  isDragging = false;
  let x=0;
  let y=0;
  if (e.type == "touchend") {
    let _e = e.changedTouches[0];
    x = firstX - _e.clientX;
    y = firstY - _e.clientY;
  } else if (e.type == "mouseup") {
    x = firstX - e.clientX;
    y = firstY - e.clientY;
  }
  if(x==0 && y==0) {
    jumpToLiveClass();
  }
};
</script>

<style>
</style>
