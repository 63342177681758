<template>
  <div id="child-wrap" class="child-wrap">
    <div v-if="bPdfImagesAvailable" class="flex-2" style="overflow: scroll">
      <template v-for="(jpg) in res.data.questions_pdf_images">
        <img :src="jpg" style="width:100%;">
        <hr/>
      </template>
    </div>
    <div v-else class="flex-1" id="adobe-dc-view"></div>
    <div class="test-paper">
      <div class="banner">
        <div class="time-cont">{{timeString}}</div>
        <img class="start-img" v-if="stateType!='done'" @click="handleSubmit" src="../../assets/hippo-paper/jiaojuan.png" />
      </div>
	  <div class="score-cont">
		  <div v-if="stateType=='done'" class="score">
		    <img src="@/assets/hippo-paper/score.png" class="score-img">
		    <div class="score-text">{{paperData.user_score}}</div>
		  </div>
		  <div v-if="stateType=='done'" class="score">
		    <img src="@/assets/hippo-paper/total.png" class="score-img">
		    <div class="score-text">{{paperData.total_score}}</div>
		  </div>
	  </div>
	  <div v-if="stateType=='done'" class="baogao-cont">
	  	<img src="@/assets/hippo-paper/baogao.png" class="score-img" @click="openBaogao">
	  </div>
      <div class="paper-cont">
        <div class="paper-ul">
          <div class="ul-item cur" @click="handleCheck(item.type_code)" :class="item.type_code==type_code?'actUl':''" v-for="(item,index) in dataList" :key="index">{{item.type_name}}</div>
        </div>
        <div class="paper-list">
          <div class="list-item" v-for="(item,index) in questionsObjext&&questionsObjext.questions" :key="index">
            <img class="tit-img" v-if="!item.explain" src="../../assets/hippo-paper/title-hui.png" />
            <img class="tit-img cur" v-else  @click="checkTit(item)" src="../../assets/hippo-paper/title-yellow.png" />
            <div class="abc">

              <!-- zhm备份：为了解决填空题的输入
              <div class="item-btn item-index">{{index+1}}</div>
              <div class="item-btn cur"
                   :class="item.my_answer==subItem?'actBtn':''"
                   @click="selectItem(subItem,item.id,item.my_answer)"
                   v-for="(subItem,subIndex) in setOptional(item.optional)">
                {{subItem}}
                <img class="pf" v-if="stateType=='done'&&item.answer == subItem" src="../../assets/hippo-paper/yes.png" />
                <img class="pf" v-if="stateType=='done'&&item.answer != item.my_answer&&item.my_answer == subItem" src="../../assets/hippo-paper/no.png" />
              </div>
              -->

              <!-- BOF：尝试增加填空题 vvvvvvvvvvv -->
              <div class="item-btn item-index">{{item.question_number}}</div>
              <template v-if="item.label==='text'" comment="text 代表填空题">
                <div v-if="stateType=='done'" class="item-btn cur" comment="已交卷">
                  {{item.my_answer}}
                  <img class="pf" v-if="item.answer == item.my_answer" src="../../assets/hippo-paper/yes.png" />
                  <img class="pf" v-else src="../../assets/hippo-paper/no.png" />
                </div>
                <input v-else comment="未交卷" type="text" class="item-btn cur"
                       :id="'text-'+item.id" v-model="textQuestionValues[item.id]"/>
              </template>
              <template v-else comment="非填空题：此处实际上不仅仅是选择题，还可能是写作题、翻译题等，应该做进一步区分">
                <div v-for="(subItem,subIndex) in setOptional(item.optional)"
                     class="item-btn cur"
                     :class="item.my_answer==subItem?'actBtn':''"
                     @click="selectItem(subItem,item.id,item.my_answer)">
                  {{subItem}}
                  <img class="pf" v-if="stateType=='done'&&item.answer == subItem" src="../../assets/hippo-paper/yes.png" />
                  <img class="pf" v-if="stateType=='done'&&item.answer != item.my_answer&&item.my_answer == subItem" src="../../assets/hippo-paper/no.png" />
                </div>
              </template>
              <!-- EOF：尝试增加填空题 ^^^^^^^^^^^ -->

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="toggleStartContainer" v-if="!toggleStart&&stateType!='done'" @click="startTest">
      <img class="start" src="../../assets/hippo-paper/start.png" />
    </div>
	<div class="modal modals" id="ScantronLoading" v-if="ScantronLoading">
	  <img class="" src="../../assets/ScantronLoading1.png" />
	  <img class="" src="../../assets/ScantronLoading2.gif" />
	</div>
    <audio ref="audioPlayer" @ended="handleAudioEnded">
      <source src="" type="audio/mp3">
    </audio>
    <!--el-dialog
      title="题目详解"
      :visible.sync="robotVisible"
      @close="closeRobot"
      width="50%">
      <div class="robot-wrap">
        <img class="robot-img" src="../../assets/hippo-paper/robot.gif" />
        <div class="robot-text">{{robotText}}</div>
      </div>
    </el-dialog-->
    <el-dialog
        title="题目详解"
        :visible.sync="robotVisible"
		    custom-class="robot-window"
        @close="closeRobot"
        width="50%">
      <div class="robot-wrap">
        <img class="robot-img" src="../../assets/hippo-paper/robot.gif" />
        <div class="robot-text">
          <textarea id="explainBox" class="my-textarea" style="position:relative;left:0%;top:0%;width:100%;height:100%;">
            {{robotText}}
          </textarea>
        </div>
      </div>
    </el-dialog>
	<el-dialog
	    title="报告"
	    :visible.sync="baogaoVisible"
		  custom-class="baogao-window"
	    @close="baogaoVisible = false"
	    width="60vh">
	  <div class="baogao-wrap" :class="`${$route.query.exam_name}-bg`">
		  <div class="wel1">亲爱的{{baogaoData.nickname}}同学，恭喜你完成了 当前的考试！</div>
		  <div class="wel2">这是你在这份试卷中的能力统计：</div>
		  <div class="ans1">与听力能力有关的题目一共{{baogaoData?.abilities?.listening?.total}}道，你做对了{{baogaoData?.abilities?.listening?.correct}}道，正确率为{{calc(baogaoData?.abilities?.listening?.correct,baogaoData?.abilities?.listening?.total)}}%</div>
		  <div class="ans2">与阅读能力有关的题目一共{{baogaoData?.abilities?.reading?.total}}道，你做对了{{baogaoData?.abilities?.reading?.correct}}道，正确率为{{calc(baogaoData?.abilities?.reading?.correct,baogaoData?.abilities?.reading?.total)}}%</div>
		  <div class="ans3">与词汇能力有关的题目一共{{baogaoData?.abilities?.vocabulary?.total}}道，你做对了{{baogaoData?.abilities?.vocabulary?.correct}}道，正确率为{{calc(baogaoData?.abilities?.vocabulary?.correct,baogaoData?.abilities?.vocabulary?.total)}}%</div>
		  <div class="ans4">与语法能力有关的题目一共{{baogaoData?.abilities?.grammar?.total}}道，你做对了{{baogaoData?.abilities?.grammar?.correct}}道，正确率为{{calc(baogaoData?.abilities?.grammar?.correct,baogaoData?.abilities?.grammar?.total)}}%</div>
		  <ul>
			  <li class="level1">{{baogaoData?.abilities?.listening?.comment}}</li>
			  <li class="level2">{{baogaoData?.abilities?.reading?.comment}}</li>
			  <li class="level3">{{baogaoData?.abilities?.vocabulary?.comment}}</li>
			  <li class="level4">{{baogaoData?.abilities?.grammar?.comment}}</li>
		  </ul>
		  <div class="end1">完成全部三个部分的考试，你还能看到更完整的能力评估。</div>
	  </div>
	</el-dialog>
  </div>
</template>

<script>
import { getPaper, getQuestions, postExaminee,getExamineeReport } from "../../api/index";
import FlipDown from "../../components/FlipDown.vue";
import Write from "../components/write.vue";
import Hearing from "../components/hearing.vue";
import Read from "../components/read.vue";
import Translate from "../components/translate.vue";
import Dictation from "../components/dictation.vue";
import MakeUp from "../components/makeUp.vue";
import Answer from "../components/answer.vue";
import Pack from "../components/pack.vue";
import Mistake from "../components/mistake.vue";
export default {
  components: {
    FlipDown,
    Write,
    Hearing,
    Read,
    Translate,
    Dictation,
    MakeUp,
    Answer,
    Pack,
    Mistake,
  },
  props: ["res"],
  data() {
    return {
      bPdfImagesAvailable: false,
      btnText: "交卷",
      moveClass: "toleft",
      isUp: false,
      dataList: [],
      activeIndex: "",
      canNext: true,
      paperData: {
        total_score: "",
        title: "",
        questions_pdf: "",
        max_time: 0,
        examinee_id: "",
        paper_id: "",
        analysis_pdf: "",
      },
      answers: [],
      types_elapsed: [],
      questionsObjext: null,
      loading: false,
      ScantronLoading: false,
      max_time: null,
      stateType: null,

      toggleStart:false,
      toggleTit:false,
      totalSeconds: 20 * 60, // 20 minutes in seconds
      timer: null,
      type_code:"",
      robotVisible:false,
      robotText:"",

      textQuestionValues: {},
      baogaoVisible:false,
      baogaoData:{}
    };
  },
  computed: {
      timeString: function() {
        const minutes = Math.floor(this.totalSeconds / 60);
        const seconds = this.totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      }
  },
  created() {},
  async mounted() {
    if (window.innerWidth < window.innerHeight) {
      document.body.style.transformOrigin = 'top left';
      document.body.style.transform = 'rotate(90deg) translateY(-100%)';
      document.body.style.width = '100vh';
      document.body.style.height = '100vw';
  	 //  document.getElementById('hippo-paper-wrap').style.height = window.innerWidth + 'px';
    }

    window.addEventListener("beforeunload", (e) => {
      this.beforeunloadHandler(e);
    });

    this.bPdfImagesAvailable = this.res.data.questions_pdf_images && this.res.data.questions_pdf_images.length>0;
//      && [32, 40, 132].includes(Number(this.$route.query.user_id));
//    console.log('this.$route.query.user_id', this.$route.query.user_id);
//    console.log('bPdfImagesAvailable ======', this.bPdfImagesAvailable);

    if(this.bPdfImagesAvailable) {
      await this.paperList(false);
    }
    else {
      if (window.AdobeDC) {
        await this.paperList(true);
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          this.paperList(true);
        });
      }
    }
  },

  destroyed() {
    this.clearCountdown();
    // this.timeEnd(1);
  },

  methods: {
    // 获取试卷
    async paperList(need) {
      const res = this.res;
      if (res.code == 0) {
        console.log(res);
        this.paperData.title = res.data.title;
        this.paperData.questions_pdf = res.data.questions_pdf;
        this.paperData.examinee_id = res.data.examinee_id;
        this.paperData.paper_id = res.data.paper_id;
        this.paperData.analysis_pdf = res.data.analysis_pdf;
        this.max_time = res.data.max_time;
        this.stateType = res.data.status;
        if(this.stateType=='done'){
          this.totalSeconds = 0;
        }
        this.paperData.user_score = res.data.user_score;
		    this.paperData.total_score = res.data.total_score;
        const obj = res.data.question_types.find(
          (item) => item.type_code.indexOf('listening')==0
        );
        if (obj) {
          this.paperData.audioList = obj.music_mp3;
        }

        if (this.stateType == "done") {
          this.btnText = "总分";
          this.$store.commit("setExamination", true);
        }

        const times = new Date();
        this.paperData.max_time = new Date(
          times.getTime() + this.max_time * 60 * 1000
        );

        this.dataList = res.data.question_types;
        this.handleCheck(
          this.dataList[0].type_code
        );

        if (need) {
          this.showPDF("adobe-dc-view", this.paperData.questions_pdf);
          !this.$store.state.examination &&
            this.showPDF("adobe-dc2-view", this.paperData.questions_pdf);
        }

      } else {
        this.$message.error("试卷获取失败 "+(res.msg||''));
      }
    },
	startTest(){
		this.toggleStart = true;
		this.startCountdown();
		if(this.dataList[0].music_mp3){
			this.playAudio(this.dataList[0].music_mp3[0]);
		}
	},
	playAudio(audio) {
	  // 获取音频元素引用并播放
	  this.$refs.audioPlayer.src = audio;
	  const player = this.$refs.audioPlayer;
	  if (player) {
		player.play().catch(error => console.error("Audio play failed", error));
	  }
	},
	handleAudioEnded() {
	  console.log("Audio ended. Handling events...");
	},
	selectItem(val,id,my_answer){
		if(this.stateType=='done'){
			return
		}
		if(val == my_answer){//点击重复的
			return
		}
		let t = 0;
		for(let item of this.answers){
			if(item.question_id == id){//老题
				item.my_answer = val;//覆盖老答案
				t = 1;
			}
		}
		if(t==0){//新题
			this.answers.push({
				question_id:id,
				my_answer:val
			})
		}
		this.setAct();
		console.dir(this.answers);
	},
	setAct(){//选色
		for(let item of this.questionsObjext.questions){
			for(let subItem of this.answers){
				if(item.id == subItem.question_id){
					item.my_answer = subItem.my_answer
				}
			}
		}
	},
	checkTit(item){
		this.robotVisible = true;
		this.robotText = item.explain;
		this.playAudio(item.explainAudioUrl);
	},
	closeRobot(){
		const player = this.$refs.audioPlayer;
		if (player) {
			player.pause();  // 暂停音频
			player.currentTime = 0;  // 将音频的当前时间设置为0
		}
	},
	setOptional(item){
		return item.split(" ");
	},
	startCountdown: function() {
	  this.timer = setInterval(() => {
		if (this.totalSeconds > 0) {
		  this.totalSeconds -= 1;
		} else {
		  this.clearCountdown();
		  this.timeEnd();
		}
	  }, 1000);
	},
	clearCountdown: function() {
	  clearInterval(this.timer);
	  this.timer = null;
	  this.totalSeconds = 0;
	},
    // pdf展示
    showPDF(id, url) {
      console.log(id, url);
      const adobeDCView = new AdobeDC.View({
        clientId: "81211a7dfbe741499e88a71fbe4c8c48",
        divId: id,
      });
      adobeDCView.previewFile({
        content: {
          location: {
            url: url,
          },
        },
        metaData: { fileName: "北京奇峰天下科技有限公司技术支持.pdf" },
      });
    },

    // 点击题型获取题目
    async handleCheck(type_code) {
	  this.type_code = type_code;
      if (this.activeIndex === type_code && !this.$store.state.examination) return;
      if (this.canNext) {
        this.loading = true;
        this.activeIndex = type_code;
        const res = await getQuestions({
          user_id: Number(this.$route.query.user_id), //用户id, //用户id
          pid: this.$route.query.pid, //合作方id
          examinee_id: Number(this.paperData.examinee_id), //考试场次id
          paper_id: Number(this.paperData.paper_id), //试卷id
          type_code: type_code, //待获取的题型代码
        });
        this.questionsObjext = res.data;

        //填空题学生答案的临时缓存
        if(this.questionsObjext) {
          for(let quest of this.questionsObjext.questions) {
            if(quest.label === 'text' && !this.textQuestionValues[quest.id]) {
              this.textQuestionValues[quest.id] = quest.my_answer||'';
            }
          }
        }

        //
        this.setAct();
        this.loading = false;
      } else {
        this.$message.warning("请先结束上一题作答");
      }
    },

    // 获取/修改 每大题答题时间
    handleEndTime(val) {
      console.log(val);
      const i = this.types_elapsed.findIndex(
        (item) => item.type_code === val.type_code
      );
      if (i >= 0) {
        this.types_elapsed.splice(i, 1);
      }
      this.types_elapsed.push(val);
    },

    // 获取/修改 每道题目的答案
    changeContents(val) {
      val.forEach((item) => {
        const i = this.answers.findIndex(
          (items) => items.question_id === item.question_id
        );
        if (i >= 0) {
          this.answers[i].my_answer = item.my_answer;
        } else {
          this.answers.push(item);
        }
      });
      console.log(this.answers);
    },
	//获取报告
	async openBaogao(){
		this.baogaoVisible = true;
		const res = await getExamineeReport({
		  user_id: Number(this.$route.query.user_id), //用户id, //用户id
		  pid: this.$route.query.pid, //合作方id
		  examinee_id_list: [Number(this.paperData.examinee_id)], //考试场次id
		});
		this.baogaoData = res.data
		const abilitiesObject = this.baogaoData.abilities.reduce((acc, current) => {
		  acc[current.ability] = current;
		  return acc;
		}, {});
		this.baogaoData.abilities = abilitiesObject;
		console.dir(this.baogaoData)
	},
	calc(num1,num2){
		return parseInt(num1/num2*100)
	},
    // 提交试卷
    handleSubmit() {
		this.$dialog.alert({
		  showCancelButton:true,
		  message: '是否确认提交试卷',
		})
	  .then(() => {
			this.clearCountdown();
			this.timeEnd();
	  })
	  .catch(() => {
		// on cancel
	  })
    },
    async timeEnd(t) {
      this.ScantronLoading = true;
      // 合并填空题的答案
      for(let k in this.textQuestionValues) {
        this.answers.push({
          question_id:k,
          my_answer:this.textQuestionValues[k]
        })
      }

      // 提交试卷请求
      const res = await postExaminee({
        user_id: Number(this.$route.query.user_id), //用户id, //用户id
        pid: this.$route.query.pid, //合作方id
        examinee_id: Number(this.paperData.examinee_id), //考试场次id
        paper_id: Number(this.paperData.paper_id), //试卷id
        answers: this.answers,
        types_elapsed: this.types_elapsed,
		    type:t||''
      });
      if (res.code == 0) {
        this.ScantronLoading = false;
        // this.btnText = "总分";
        // this.$store.commit("setExamination", true);
        // this.paperData.user_score = res.data.total_score.type_score;
        // this.handleCheck(
        //   this.dataList[0].type_code
        // );
        // this.showPDF("adobe-dc-view", this.paperData.analysis_pdf);
//        window.location.reload();
        let p = 'examinee_id='+this.paperData.examinee_id;
        let url = window.location.href.replace('examinee_id=0', p);
        window.location.href = url;
        window.location.reload();
      } else {
        this.ScantronLoading = false;
        this.$message.error("提交失败请重新提交");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.child-wrap{
	display: flex;
	height: 100%;
	position: relative;
	.flex-1{
		flex: 1;
	}
  .flex-2{
    flex: 1;
    padding-left: 5%;
    padding-right: 5%;
  }
	.modal{
		position: absolute;
		z-index: 10;
		transform: translateZ(10px);
		width: 100%;
		height: 100%;
		background-color: rgba(115, 149, 221, .5);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
    cursor: pointer;
		.start{
      position: absolute;
      top:10%;
			cursor: pointer;
		}
	}
	.modals{
		background-color: rgba(0, 0, 0, .3);
	}
	#adobe-dc-view{
		height: 100%;
	}
	.test-paper{
		background-color: rgba(251,229,214);
		height: 100%;
		overflow: auto;
	}
	.banner{
//		width:878px;
//		height:234px;
    width:966px;
    height:257px;
		background: url('../../assets/hippo-paper/banner.png');
		background-size: 100% 100%;
		position: relative;
		.time-cont{
			background-color: #000;
			color: white;
			font-size: 32px;
			font-weight: bold;
			width:174px;
			height: 60px;
			line-height: 60px;
			text-align: center;
			position: absolute;
			right: 40px;
			bottom: 80px;
		}
		.start-img{
			position: absolute;
			right: 40px;
			bottom: 10px;
			cursor: pointer;
			width:174px;
			height: 60px;
		}
	}
	.score-cont{
		display: flex;
		justify-content: center;
		.score{
		  margin:0 10px;
		  position: relative;
		  width: 320px;
		  height: 91px;
		  .score-img{
		    position:absolute;
		    left:0;
			height: 91px;
		  }
		  .score-text{
		    color:black;
		    font-size:3vw;
		    font-weight:bolder;
		    position:absolute;
		    right:30px;
		    bottom:10px;
		  }
		}
	}
	.baogao-cont{
		display: flex;
		justify-content: center;
		img{
			width:200px;
			height: 97px;
			cursor: pointer;
		}
	}
	.paper-cont{
		display: flex;
		height: calc(100% - 234px);
		padding: 0 20px 0 60px;
		.paper-ul{
			width: 80px;
			.ul-item{
				background-image: url('../../assets/hippo-paper/hui-t.png');
				margin: 5px 0;
				width: 80px;
				height: 185px;
				background-size: 80px 185px;
				font-size: 30px;
				writing-mode: vertical-rl; /* 或者使用 vertical-lr */
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
			}
			.actUl{
				background-image: url('../../assets/hippo-paper/yellow-t.png');
			}
		}
		.paper-list{
			flex: 1;
			margin-left: 20px;
			.list-item{
				margin-bottom: 50px;
				padding-right: 20px;
				.tit-img{
          width:30%;
				}
				.abc{
					border: 5px solid #000;
					border-radius: 5px;
					display: flex;
					margin-top: -5px;
					.item-index{
						background-color: rgb(178, 221, 223);
					}
					.item-btn{
						flex: 1;
						border-right: 1px solid #000;
						padding: 10px 0;
						text-align: center;
						font-weight: 900;
						font-size: 36px;
						position: relative;
						.pf{
							position: absolute;
							bottom:0px;
							right:0px;
							height:40px;
						}
					}
					.actBtn{
						background-color: #000;
						color: #fff;
					}
				}
			}
		}
	}
	.cur{
		cursor: pointer;
	}
	.robot-wrap{
		display: flex;
		align-items: flex-end;
		.robot-img{
			width:182px;
			height:258px;
			margin-right:20px;
			@media screen and (min-width: 0px) and (max-width: 540px) {
				width:10vw;
				height:14vw;
				margin-right:3vw;
			}
		}
		.robot-text0{
			background-image: url('../../assets/hippo-paper/area.png');
			width:1113px;
			height:308px;
			padding: 33px 35px 50px 35px;
			box-sizing: border-box;
			background-size: 100% 100%;
		}
		.robot-text{
		  background-image: url('../../assets/hippo-paper/area2.png');
		  width:1108px;
		  height:624px;
		  padding: 33px 35px 50px 35px;
		  box-sizing: border-box;
		  background-size: 100% 100%;
		  @media screen and (min-width: 0px) and (max-width: 540px) {
		  	flex:1;
			height: 50vw;
			padding: 5vw 5vw 7vw 5vw;
		  }
		}
	}
	.baogao-wrap{
		width:60vh;
		height:90vh;
		background-size: 100% 100%;
		position: relative;
		font-size: 1.5vh;
		color: #000;
		div{
			width: 100%;
			position: absolute;
			text-align: left;
			left: 8vh;
		}
		ul{
			overflow-y: auto;
			position: absolute;
			list-style-type: circle;
			width: 45vh;
			left: 8vh;
			li{
				margin: 10px;
			}
		}
		.wel1{
			top: 31vh;
			left: 0;
			text-align: center;
		}
		.wel2{
			top: 33.7vh;
			left: 0;
			text-align: center;
		}
	}
	.Listening-bg{
		background-image: url('../../assets/hippo-home/listening-UI.png');
		ul{
			height: 12vh;
			top:65vh;
		}
		.ans1{
			top: 40vh;
		}
		.ans2{
			top: 46vh;
		}
		.ans3{
			top: 52vh;
		}
		.ans4{
			top: 57.6vh;
		}
		.end1{
			top: 78vh;
			font-weight: bold;
			text-align: left;
		}
	}
	.ReadingWriting-bg{
		background-image: url('../../assets/hippo-home/Reading & Writing-UI.png');
		ul{
			height: 12vh;
			top:69vh;
		}
		.ans1{
			display: none;
		}
		.ans2{
			top: 44vh;
		}
		.ans3{
			top: 52.5vh;
		}
		.ans4{
			top: 60.5vh;
		}
		.level1{
			display: none;
		}
		.end1{
			top: 81vh;
			font-weight: bold;
			text-align: left;
		}
	}
	.UseOfEnglish-bg{
		background-image: url('../../assets/hippo-home/Use of English-UI.png');
		ul{
			height: 12vh;
			top:66vh;
		}
		.ans1{
			top: 40vh;
			display: none;
		}
		.ans2{
			top: 46vh;
		}
		.ans3{
			top: 52vh;
			display: none;
		}
		.ans4{
			top: 57.6vh;
		}
		.level1{
			display: none;
		}
		.level3{
			display: none;
		}
		.end1{
			top: 78vh;
			font-weight: bold;
			text-align: left;
		}
	}
}
.my-textarea {
  border-style:none;
  background-color:#00000000;
  outline:none;
  resize:none;
}
.my-textarea::-webkit-scrollbar{
  width:5px;
}
/* 添加滑块样式 */
.my-textarea::-webkit-scrollbar-thumb {
  background-color: #8c8c8c;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius: 3px;
}

/* 添加角度样式 */
.my-textarea::-webkit-scrollbar-corner {
  background-color: #f5f5f5;
}
/* 添加轨道样式 */
#explainBox::-webkit-scrollbar-track {
  background-color: #e4e4e3;
  border-radius: 3px;
}
#explainBox::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 3px;
}
::v-deep .robot-window{
	@media screen and (min-width: 0px) and (max-width: 540px) {
		margin-top:20px!important;
	}
}
::v-deep .baogao-window{
	margin-top: 20px!important;
	.el-dialog__body{
		padding: 0;
	}
}
</style>
