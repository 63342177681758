<template>
  <div v-html="renderedMarkdown"></div> <!-- 使用 v-html 来安全显示 HTML 内容 -->
</template>

<script>
import MarkdownIt from 'markdown-it';

export default {
  props: ['content'],
  data() {
    return {
      md: new MarkdownIt({
        html: true,        // 启用 HTML 支持
        linkify: true,     // 自动将 URL 转换为链接
        typographer: true  // 使用智能标点
      }),
      renderedMarkdown: ''
    };
  },
  watch: {
    content: {
      immediate: true,
      handler(newVal) {
        this.renderContent(newVal);
      }
    }
  },
  methods: {
    cleanInput(str) {
		str = str.replace(/<br\s*\/?>/gi, "\n");
		console.dir(str)
      return str;
        // .replace(/<br\s*\/?>/gi, '\n')
        // .replace(/&nbsp;/g, ' ')
        // .replace(/<[^>]*>/g, '')
        // .trim();
    },
    renderContent(content) {
      const cleanedContent = this.cleanInput(content.data);
      this.renderedMarkdown = this.md.render(cleanedContent);
			// console.dir(this.renderedMarkdown)
   //    this.$nextTick(() => {
			// 	console.dir(window.MathJax)
   //      if (window.MathJax) {
   //        window.MathJax.typesetPromise();
   //      }
   //    });
    }
  }
};
</script>

<style scoped>
/* 你可以根据需要自定义样式 */
</style>
