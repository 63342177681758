import Vue from 'vue'
import Vuex from 'vuex'
// import { exam, audioState } from '../utils/enum'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    examination: false, //是否提交试卷
    isAudio: false, //是否播放音频
    dictationText: '',
    makeUpText: ''
  },
  // getters: {
  //   getExamination() {
  //     return window.sessionStorage.getItem(exam)
  //   },
  //   getAudioState() {
  //     return window.sessionStorage.getItem(audioState)
  //   }
  // },
  mutations: {
    setExamination(state, value) {
      state.examination = value
      // window.sessionStorage.setItem(exam, value)
    },
    setAudioState(state, value) {
      state.isAudio = value
      // window.sessionStorage.setItem(audioState, value)
    },
    setDictationText(state, value) {
      state.dictationText = value
    },
    setMakeUpText(state, value) {
      state.makeUpText = value
    }
  },
  // 处理异步
  // actions: {
  // },
  // 分模块
  // modules: {
  // }
})
