<template>
  <!-- 翻译 -->
  <div class="translate">
    <el-button
      v-if="!$store.state.examination"
      class="btn"
      type="primary"
      @click="handleClick"
      >{{ btntext }}</el-button
    >
    <div class="score" v-if="$store.state.examination">
      <span>{{ value ? value.score : "" }}</span>
      <p>得分：{{ value ? value.right : "" }}</p>
      <p>扣分：{{ value ? value.error : "" }}</p>
      <p>得分率：{{ value ? value.accuracy : "" }}%</p>
      <p>耗时：{{ value ? value.elapsed : "" }}分钟</p>
    </div>
	<div class="cont" v-for="(item, index) in arr">
		<div class="first_title">{{item.first_title}}</div>
		<div class="list" v-for="(items, index) in item.child">
			  <div class="second_title">{{items.second_title}}</div>
			  <div v-for="(itemss,index) in items.child" key="index">
				  <div class="preset_text">{{itemss.preset_text}}</div>
				  <el-input
				    class="translate-textarea"
				    type="textarea"
				    placeholder="此处写翻译正文"
				    v-model="itemss.my_answer"
				    :disabled="isStart"
				  >
				  </el-input>
			  </div>
		<!-- 	  <p v-show="$store.state.examination" class="ai_result">
			    {{ ai_result }}
			  </p> -->
			</div>
	</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    examinee_id: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      btntext: "开始答题",
      timeData: 0,
      timer: null,
      isStart: true,
    };
  },
  // created() {
  //   if (this.value?.questions) {
  //     this.Content = this.value.questions[0].my_answer;
  //   }
  // },
  watch: {

  },
  computed: {
	  arr() {
	  	if(this.value&&this.value.questions){
			let aa = this.value.questions;
			var result = [];

			for (var i = 0; i < aa.length; i++) {
			var obj = aa[i];
			var firstVal = obj.first_title;
			var secondVal = obj.second_title;
			
			var firstIndex = -1;
			for (var j = 0; j < result.length; j++) {
			if (result[j].first_title === firstVal) {
			firstIndex = j;
			break;
			}
			}
			
			if (firstIndex === -1) {
			result.push({ first_title: firstVal, child: [] });
			firstIndex = result.length - 1;
			}
			
			var secondIndex = -1;
			for (var k = 0; k < result[firstIndex].child.length; k++) {
			if (result[firstIndex].child[k].second_title === secondVal) {
			secondIndex = k;
			break;
			}
			}
			
			if (secondIndex === -1) {
			result[firstIndex].child.push({ second_title: secondVal, child: [] });
			secondIndex = result[firstIndex].child.length - 1;
			}
			
			result[firstIndex].child[secondIndex].child.push(obj);
			}
			
			console.log(result);
	  		return result
	  	}else{
	  		return []
	  	}
	  },
    // ai_result() {
    //   return this.value?.questions ? this.value.questions[0].ai_result : "";
    // },
  },
  methods: {
    handleClick() {
      this.$emit("next", !this.isStart);

      this.isStart = !this.isStart;
      if (!this.isStart) {
        this.timer = setInterval(() => {
          this.timeData++;
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.timer = null;
		let ansArr = [];
		for(let i in this.arr){
			for(let j in this.arr[i].child){
				for(let k in this.arr[i].child[j].child){
				  ansArr.push({
				    question_id: this.arr[i].child[j].child[k].id,
				    my_answer: this.arr[i].child[j].child[k].my_answer,
				  })
				}
			}
		}
        this.$emit("changeContent", ansArr);
        this.$emit("endTime", {
          type_code: this.value.type_code,
          elapsed: Math.round(this.timeData / 60),
        });
      }
      this.btntext = this.isStart ? "开始答题" : "结束答题";
    },
  },
};
</script>

<style lang="less" scoped>
.translate {
  position: relative;
  padding: 5px 20px 20px;
  border: 2px solid #ccc;
  height: 92%;
  width: 94%;
  border-radius: 20px;
  overflow: auto;
  .btn {
    margin: 10px 0;
  }
  &-textarea {
    margin-top: 1%;
    width: 96%;
    height: 93%;
    ::v-deep textarea {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 15px;
      font-size: 22px;
    }
  }
}
.ai_result {
  position: absolute;
  bottom: 2%;
  left: 4%;
  color: red;
  font-size: 23px;
}
.score {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 220px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  z-index: 10;
  background: #fff;
  span {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 50px;
    font-style: italic;
  }
  p {
    font-size: 22px;
  }
}
@media (max-width: 640px) {
  .translate-textarea {
    width: 98%;
    height: 83%;
  }
  .score {
    width: unset;
    padding: 10px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
.cont{
	overflow: auto;
	height: calc(100% - 50px);
}
.preset_text{
	margin-top: 10px;
}
.first_title{
	text-align: center;
	font-size: 18px;
	font-weight: bold;
}
.second_title{
	margin-top: 10px;
	margin-bottom: 10px;
	padding-bottom: 5px;
	font-weight: bold;
	border-bottom: 1px dashed #ccc;
}
</style>