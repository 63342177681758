<template>
  <div>
		<!-- 第一部分：音频播放 -->
		<div id="audioStep1" class="step">
		  <h2>声音播放测试</h2>
		  <!-- 点击播放图标 -->
		  <img id="playButton" class="play-btn" src="https://static.bluebirdabc.com/ai-agent/img/tasks/f1372e2d3ff291dcc92ca7c00144719470d7f413.png" alt="播放音频" style="width: 112px; height: 98px;">
			<!-- 隐藏的音频播放器，只有点击播放按钮后开始播放 -->
		  <audio id="audioPlayer">
		    <source src="https://static.bluebirdabc.com/lesson/material/nopid/1111/1676ac3bbcca6ca6de8afd2f44e628142a3823ca.mp3" type="audio/mp3">
		    您的浏览器不支持音频播放。
		  </audio>
		</div>
		<img id="loadingImg" style="display:none;" class="loading-img" src="../../assets/ket-paper/play.gif"/>
		
		<!-- 第二部分：选择能听见或听不见 -->
		<div id="audioStep2" class="step" style="display:none;">
		  <h2>声音播放测试</h2>
			<div class="btn-group">
				<el-button id="canHear" type="success">能听见</el-button>
				<el-button id="cantHear" type="warning">听不见</el-button>
			</div>
		</div>
		
		<!-- 第三部分：重测和说明文字 -->
		<div id="audioStep3" class="step" style="display:none;">
		  <h2>声音播放测试</h2>
		  <div class="info">
		    <p>1. 检查是否打开了网页对音频播放的权限</p>
		    <p>2. 检查电脑系统是否是静音或音量太小</p>
		  </div>
			<el-button id="retryButton" type="primary">重测</el-button>
		</div>
	</div>
</template>

<script>

export default {
  props: [],
  data() {
    return {

    };
  },
	mounted() {
		let that = this;
		// 获取按钮和页面步骤的元素
		 const playButton = document.getElementById("playButton");
		 const loadingImg = document.getElementById("loadingImg");
		 const audioPlayer = document.getElementById("audioPlayer");
		 const audioStep1 = document.getElementById("audioStep1");
		 const audioStep2 = document.getElementById("audioStep2");
		 const audioStep3 = document.getElementById("audioStep3");
		 const canHearButton = document.getElementById("canHear");
		 const cantHearButton = document.getElementById("cantHear");
		 const retryButton = document.getElementById("retryButton");
		
		 // 当点击按钮时播放音频
		 playButton.addEventListener("click", function() {
		   audioPlayer.play(); // 播放音频
		   audioStep1.style.display = "none"; // 隐藏第一部分
			 loadingImg.style.display = "block"; // 隐藏第一部分
		   audioPlayer.onended = function() {
				 loadingImg.style.display = "none"; // 隐藏第一部分
		     // 音频播放结束后，显示第二部分
		     audioStep2.style.display = "flex"; 
		   };
		 });
		
		// 点击 "能听见" 跳转到麦克风测试页面
		 canHearButton.addEventListener("click", function() {
		   // 跳转到麦克风测试页面的本地地址
			 that.$emit("changeTestType",1);
		 });
		
		 // 点击 "听不见" 进入第三步，恢复显示播放按钮和重测按钮，并显示说明文字
		 cantHearButton.addEventListener("click", function() {
		   audioStep2.style.display = "none"; // 隐藏第二部分
		   audioStep3.style.display = "flex"; // 显示第三部分
		   playButton.style.display = "block"; // 显示播放按钮
		   retryButton.style.display = "block"; // 显示重测按钮
		 });
		
		 // 点击重测按钮，重新播放音频
		 retryButton.addEventListener("click", function() {
		   audioStep3.style.display = "none";
		   audioStep1.style.display = "flex";
		   playButton.disabled = false; // 重新启用播放按钮
		   // audio.currentTime = 0; // 重置音频播放时间
		 });
	},
  methods: {

  }
};
</script>

<style scoped lang="scss">
/* 你可以根据需要自定义样式 */
.step{
	background-color: #fff;
	border-radius: 10px;
	width: 800px;
	height: 600px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	img{
		cursor: pointer;
	}
	h2{
		margin-top: 0;
		margin-bottom: 80px;
	}
	.btn-group{
		display: flex;
		justify-content: space-around;
	}
	.info{
		margin-bottom: 80px;
	}
}
.loading-img{
	width: 600px;
}
</style>
