<template>
  <!-- 写作 -->
  <div class="write">
    <el-button v-if="!$store.state.examination" class="btn" type="primary" @click="handleClick">{{ btntext }}</el-button>
    <div class="score" v-if="$store.state.examination">
      <span>{{ value ? value.score : "" }}</span>
      <p>得分：{{ value ? value.right : "" }}</p>
      <p>扣分：{{ value ? value.error : "" }}</p>
      <p>得分率：{{ value ? value.accuracy : "" }}%</p>
      <p>耗时：{{ value ? value.elapsed : "" }}分钟</p>
    </div>
    <div style="height: 98%; position: relative">
      <div class="write-title">
        <el-input :disabled="isStart" v-model="writeTitle" placeholder="此处写文章标题"></el-input>
      </div>
      <el-input class="write-textarea" type="textarea" placeholder="此处写正文" v-model="writeContent" :disabled="isStart">
      </el-input>
      <p v-show="$store.state.examination" class="ai_result">
        {{ ai_result }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    examinee_id: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      writeTitle: "",
      writeContent: "",
      btntext: "开始答题",
      isStart: true,
      timer: null,
      timeData: 0,
    };
  },
  watch: {
    value: {
      handler(val, oldval) {
        if (this.examinee_id == 0) return;
        if (val.questions[0].my_answer) {
          const obj = val.questions[0].my_answer.split(",");
          this.writeTitle = obj[0];
          this.writeContent = obj.splice(1).join(',');
          console.log(this.writeContent);
        }
      },
      deep: true,
    },
  },
  computed: {
    ai_result() {
      return this.value?.questions ? this.value.questions[0].ai_result : "";
    },
  },
  methods: {
    handleClick() {
      this.$emit("next", !this.isStart);
      this.isStart = !this.isStart;
      if (!this.isStart) {
        this.timer = setInterval(() => {
          this.timeData++;
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.timer = null;

        this.$emit("changeContent", [
          {
            question_id: this.value.questions[0].id,
            my_answer: this.writeTitle + "," + this.writeContent,
          },
        ]);
        this.$emit("endTime", {
          type_code: this.value.type_code,
          elapsed: Math.round(this.timeData / 60),
        });
      }
      this.btntext = this.isStart ? "开始答题" : "结束答题";
    },
  },
};
</script>

<style lang="less" scoped>
.write {
  position: relative;
  padding: 5px 20px 20px;
  border: 2px solid #ccc;
  height: 92%;
  width: 94%;
  border-radius: 20px;
  overflow: auto;
  .btn {
    margin: 10px 0;
  }
  &-title {
    text-align: center;

    ::v-deep .el-input__inner {
      width: 70%;
      height: unset;
      border: 0;
      border-bottom: 1px solid #818181 !important;
      text-align: center;
      font-size: 35px;
      padding-bottom: 7px;
    }
  }

  &-textarea {
    margin-top: 30px;
    width: 95%;
    height: 85%;

    ::v-deep textarea {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 15px;
      font-size: 28px;
    }
  }
}

.score {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 220px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  z-index: 10;
  background: #fff;

  span {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 50px;
    font-style: italic;
  }

  p {
    font-size: 22px;
  }
}

.ai_result {
  width: 92%;
  position: absolute;
  bottom: 4%;
  left: 2%;
  color: red;
  font-size: 23px;
}

@media (max-width: 640px) {
  .write-textarea {
    width: 98%;
    height: 75%;
  }

  .score {
    width: unset;
    padding: 10px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
</style>