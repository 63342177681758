<template>
  <!-- 作答 -->
  <div class="answer">
    <el-button
      v-if="!$store.state.examination"
      class="btn"
      type="primary"
      @click="handleClick"
      >{{ btntext }}</el-button
    >
    <div class="score" v-if="$store.state.examination">
      <span>{{ value ? value.score : "" }}</span>
      <p>得分：{{ value ? value.right : "" }}</p>
      <p>扣分：{{ value ? value.error : "" }}</p>
      <p>得分率：{{ value ? value.accuracy : "" }}%</p>
      <p>耗时：{{ value ? value.elapsed : "" }}分钟</p>
    </div>
    <div class="item" v-for="item in arr" :key="item.id">
      <span>{{ item.question_number }}：</span>
      <el-input
        class="answer-textarea"
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 4 }"
        placeholder="请输入内容"
        v-model="answerList[`${item.id}`]"
        :disabled="isStart"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    examinee_id: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      answerList: {},
      btntext: "开始答题",
      timeData: 0,
      timer: null,
      isStart: true,
    };
  },
  watch: {
    value: {
      handler(val, oldval) {
        if (this.examinee_id == 0) return;
        val.questions.forEach((item) => {
          if (item.my_answer) {
            this.answerList[item.id] = item.my_answer;
          }
        });
      },
      deep: true,
    },
  },
  computed: {
    arr() {
      return this.value?.questions || [];
    },
  },
  methods: {
    handleClick() {
      this.$emit("next", !this.isStart);
      this.isStart = !this.isStart;
      if (!this.isStart) {
        this.timer = setInterval(() => {
          this.timeData++;
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.timer = null;

        const arr = Object.keys(this.answerList).map((key) => {
          return {
            question_id: Number(key),
            my_answer: this.answerList[key],
          };
        });
        console.log(arr);

        this.$emit("changeContent", arr);

        this.$emit("endTime", {
          type_code: this.value.type_code,
          elapsed: Math.round(this.timeData / 60),
        });
      }
      this.btntext = this.isStart ? "开始答题" : "结束答题";
    },
  },
};
</script>

<style lang="less" scoped>
.answer {
  position: relative;
  padding: 5px 20px 20px;
  border: 2px solid #ccc;
  height: 92%;
  width: 94%;
  border-radius: 20px;
  overflow: auto;
  .item {
    margin-top: 10px;
    display: flex;
    align-items: center;
    span {
      width: 30px;
      text-align: center;
    }
  }

  &-textarea {
    width: 75%;
    height: 100%;
    ::v-deep textarea {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 15px;
      font-size: 22px;
    }
  }
}
.score {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 220px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  z-index: 10;
  background: #fff;
  span {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 50px;
    font-style: italic;
  }
  p {
    font-size: 22px;
  }
}
@media (max-width: 640px) {
  .answer .item span {
    width: 42px;
  }
  .answer .item {
    margin-top: 8px;
  }
  .score {
    width: unset;
    padding: 10px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
</style>