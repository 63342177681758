<template>
  <div id="child-wrap" class="child-wrap">
    <div v-if="bPdfImagesAvailable" class="flex-2" style="overflow: scroll">
      <template v-for="(jpg) in res.data.questions_pdf_images">
        <img :src="jpg" style="width:100%;">
        <hr/>
      </template>
    </div>
    <div v-else class="flex-1" id="adobe-dc-view"></div>
    <div class="test-paper">
      <div class="banner">
<!-- 		  <audio id="audio" controls></audio> -->
		<img class="banner-title" src="../../assets/hunan-paper/title.png" />
		<img class="banner-timer" src="../../assets/hunan-paper/timer.png" />
        <div class="time-cont">{{timeString}}</div>
        <img class="start-img" v-if="stateType!='done'" @click="handleSubmit" src="../../assets/hunan-paper/jiaojuan.png" />
		<div class="banner-text">
			<div>{{first_title}}</div>
			<ul>
				<li>{{second_title}}</li>
			</ul>
		</div>
      </div>
	  <div class="score-cont">
		  <div v-if="stateType=='done'" class="score">
		    <img src="@/assets/hunan-paper/score.png" class="score-img">
		    <div class="score-text">{{paperData.user_score}}</div>
		  </div>
		  <div v-if="stateType=='done'" class="score">
		    <img src="@/assets/hunan-paper/total.png" class="score-img">
		    <div class="score-text">{{paperData.total_score}}</div>
		  </div>
	  </div>
	  <div v-if="stateType=='done'" class="baogao-cont">
	  	<img src="@/assets/hunan-paper/baogao.png" class="score-img" @click="openBaogao">
	  </div>
      <div class="paper-cont">
        <div class="paper-ul">
          <div class="ul-item cur" @click="handleCheck(item)" :class="item.type_code==type_code?'actUl':''" v-for="(item,index) in dataList" :key="index">{{item.type_name}}</div>
        </div>
        <div class="paper-list">
          <div class="list-item" v-for="(item,index) in questionsObjext&&questionsObjext.questions" :key="index">
			 <div class="areaClass" v-if="questionsObjext&&(questionsObjext.type_code == 'writing-writing'||questionsObjext.type_code == 'writing-complete'||questionsObjext.type_code == 'writing-answer')" comment="textarea题">
			   <div v-if="stateType=='done'" class="item-btn cur areaAnswer" comment="已交卷">
			     {{item.my_answer}}
			     <img class="pf" v-if="item.true_or_false == 1" src="../../assets/hippo-paper/yes.png" />
			     <img class="pf" v-else-if="item.true_or_false == 0" src="../../assets/hippo-paper/no.png" />
			   </div>
			   <textarea v-else comment="未交卷" class="item-btn cur areaInput" :id="'text-'+item.id" :placeholder="item.preset_text" v-model="textQuestionValues[item.id]"/>
			   <div class="hunanjx" v-if="item.explain||item.voice_explain" @click="checkTit(item)">
			   	题目解析
			   </div>
			 </div>
            <div class="abc" v-else-if="item.label == 'choose'">
				<div class="item-btn item-index">{{item.question_number}}</div>
                <div v-for="(subItem,subIndex) in setOptional(item.optional)"
                     class="item-btn flex-1">
                  {{subItem}}
				  <div class="sel-btn" :class="item.my_answer==subItem?'actBtn':''" @click="selectItem(subItem,item.id,item.my_answer)"></div>
                  <img class="pf" v-if="stateType=='done'&&item.true_or_false == 1&&item.my_answer == subItem" src="../../assets/hunan-paper/yes.png" />
                  <img class="pf" v-else-if="stateType=='done'&&item.true_or_false == 0&&item.my_answer == subItem" src="../../assets/hunan-paper/no.png" />
                </div>
				<div class="hunanjx" v-if="item.explain||item.voice_explain" @click="checkTit(item)">
					题目解析
				</div>
            </div>
			<div class="abc" v-else-if="item.label==='text'||item.label==='answer-text'" comment="text 代表填空题">
			  <div class="item-btn item-index tkIndex">{{item.question_number}}</div>
			  <div v-if="stateType=='done'" class="item-btn cur pf-cont" comment="已交卷">
			    {{item.my_answer}}
				<template v-if="item.label==='text'">
			     <img class="pf" v-if="item.true_or_false == 1" src="../../assets/hippo-paper/yes.png" />
			     <img class="pf" v-else-if="item.true_or_false == 0" src="../../assets/hippo-paper/no.png" />
				</template>
			  </div>
			  <input v-if="stateType!='done'" comment="未交卷" type="text" class="item-btn cur flex-1 tkInput" :id="'text-'+item.id" v-model="textQuestionValues[item.id]"/>
				<div class="hunanjx" v-if="item.explain||item.voice_explain" @click="checkTit(item)">
					题目解析
				</div>
			</div>
			<div class="s60" v-else>
				<template v-if="stateType=='done'">
					<img class="" v-if="item.voice_url&&!item.isPlay" @click="clickPlay(item)" src="../../assets/hunan-paper/play.png" alt="">
					<img class="" v-else-if="item.voice_url&&item.isPlay" @click="clickStop(item)" src="../../assets/hunan-paper/stop.png" alt="">
				</template>
				<template v-else>
					<div class="voiceBtn" v-if="actVoice===item.id">
						<el-progress class="bor" type="circle" :width="progressWidth" :show-text="false" :percentage="item.percentage"></el-progress>
						<img class="actimg" @click="stopVoice(item)" src="../../assets/hunan-paper/macing.png" alt="">
					</div>
					<img v-else @click="startVoice(item)" class="list-img" :class="uploadIdArr.indexOf(item.id)!=-1?'checkImg':''" src="../../assets/hunan-paper/mac.png" alt="">
				</template>
				<div class="s60-txt flex-1">{{item.question}}</div>
				<div class="hunanjx" v-if="item.explain||item.voice_explain" @click="checkTit(item)">
					题目解析
				</div>
			</div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="toggleStartContainer" v-if="!toggleStart&&stateType!='done'" @click="startTest">
      <img class="start" src="../../assets/hunan-paper/start.png" />
    </div>
	<div class="modal modals" id="ScantronLoading" v-if="ScantronLoading">
	  <img class="" src="../../assets/ScantronLoading1.png" />
	  <img class="" src="../../assets/ScantronLoading2.gif" />
	</div>
    <audio ref="audioPlayer" @ended="handleAudioEnded">
      <source src="" type="audio/mp3">
    </audio>
    <!--el-dialog
      title="题目详解"
      :visible.sync="robotVisible"
      @close="closeRobot"
      width="50%">
      <div class="robot-wrap">
        <img class="robot-img" src="../../assets/hunan-paper/robot.gif" />
        <div class="robot-text">{{robotText}}</div>
      </div>
    </el-dialog-->
    <el-dialog
        title="题目详解"
        :visible.sync="robotVisible"
		    custom-class="robot-window"
        @close="closeRobot"
        width="50%">
      <div class="robot-wrap">
		  <div class="img-cont">
			<img class="play" v-if="!isRobotPlay" @click="robotPlay()" src="../../assets/hunan-paper/play.png" alt="">
			<img class="pause" v-else @click="robotPause()" src="../../assets/hunan-paper/pause.png" alt="">
			<img class="stop" @click="closeRobot()" src="../../assets/hunan-paper/stop.png" alt="">
			<img class="robot-img" src="../../assets/hunan-paper/robot.gif" />
		  </div>
        <div class="robot-text">
			<div class="btn-group">
				<div v-if="robotText" @click="tabClick(1)" :class="actTab == 1?'act-tab':''" class="tab-btn">内容</div>
				<div v-if="voiceExplain" @click="tabClick(2)" :class="actTab == 2?'act-tab':''" class="tab-btn">发音</div>
				<div class="score-btn">得分（满分<span style="color: #588E30;"> {{maxScore}} </span>）:<span style="color: #C00000;"> {{myScore}} </span>分</div>
			</div>
          <textarea id="explainBox" class="my-textarea" style="position:relative;left:0%;top:0%;width:100%;height:100%;">
            {{actTab==1?robotText:voiceExplain}}
          </textarea>
        </div>
      </div>
    </el-dialog>
	<el-dialog
	    title="报告"
	    :visible.sync="baogaoVisible"
		  custom-class="baogao-window"
	    @close="baogaoVisible = false"
	    width="60vh">
	  <div class="baogao-wrap" :class="`Listening-bg`">
		  <div class="wel1">亲爱的{{baogaoData.nickname}}同学，恭喜你完成了 当前的考试！</div>
		  <div class="wel2">这是你在这份试卷中的能力统计：</div>
		  <div class="ans1">与听力能力有关的题目一共{{baogaoData?.abilities?.listening?.total}}道，你做对了{{baogaoData?.abilities?.listening?.correct}}道，正确率为{{calc(baogaoData?.abilities?.listening?.correct,baogaoData?.abilities?.listening?.total)}}%</div>
		  <div class="ans2">与阅读能力有关的题目一共{{baogaoData?.abilities?.reading?.total}}道，你做对了{{baogaoData?.abilities?.reading?.correct}}道，正确率为{{calc(baogaoData?.abilities?.reading?.correct,baogaoData?.abilities?.reading?.total)}}%</div>
		  <div class="ans3">与词汇能力有关的题目一共{{baogaoData?.abilities?.vocabulary?.total}}道，你做对了{{baogaoData?.abilities?.vocabulary?.correct}}道，正确率为{{calc(baogaoData?.abilities?.vocabulary?.correct,baogaoData?.abilities?.vocabulary?.total)}}%</div>
		  <div class="ans4">与语法能力有关的题目一共{{baogaoData?.abilities?.grammar?.total}}道，你做对了{{baogaoData?.abilities?.grammar?.correct}}道，正确率为{{calc(baogaoData?.abilities?.grammar?.correct,baogaoData?.abilities?.grammar?.total)}}%</div>
		  <ul>
			  <li class="level1">{{baogaoData?.abilities?.listening?.comment}}</li>
			  <li class="level2">{{baogaoData?.abilities?.reading?.comment}}</li>
			  <li class="level3">{{baogaoData?.abilities?.vocabulary?.comment}}</li>
			  <li class="level4">{{baogaoData?.abilities?.grammar?.comment}}</li>
		  </ul>
		  <div class="end1">完成全部三个部分的考试，你还能看到更完整的能力评估。</div>
	  </div>
	</el-dialog>
  </div>
</template>

<script>

import {getPaper, getQuestions, postExaminee, getExamineeReport, signForm} from "../../api/index";
import FlipDown from "../../components/FlipDown.vue";
import Write from "../components/write.vue";
import Hearing from "../components/hearing.vue";
import Read from "../components/read.vue";
import Translate from "../components/translate.vue";
import Dictation from "../components/dictation.vue";
import MakeUp from "../components/makeUp.vue";
import Answer from "../components/answer.vue";
import Pack from "../components/pack.vue";
import Mistake from "../components/mistake.vue";
import '../../utils/HZRecorder';

let mediaRecorder;
let audioChunks = [];
export default {
  components: {
    FlipDown,
    Write,
    Hearing,
    Read,
    Translate,
    Dictation,
    MakeUp,
    Answer,
    Pack,
    Mistake,
  },
  props: ["res"],
  data() {
    return {
      bPdfImagesAvailable: false,
      btnText: "交卷",
      moveClass: "toleft",
      isUp: false,
      dataList: [],
      activeIndex: "",
      canNext: true,
      paperData: {
        total_score: "",
        title: "",
        questions_pdf: "",
        max_time: 0,
        examinee_id: "",
        paper_id: "",
        analysis_pdf: "",
      },
      answers: [],
      types_elapsed: [],
      questionsObjext: null,
      loading: false,
      ScantronLoading: false,
      max_time: null,
      stateType: null,

      toggleStart:false,
      toggleTit:false,
      totalSeconds: 50 * 60, // 20 minutes in seconds
      timer: null,
      type_code:"",
      textQuestionValues: {},
      baogaoVisible:false,
      baogaoData:{},
	  first_title:"",
	  second_title:"",
	  actVoice:"",
	  voiceUrl:"",
	  uploadIdArr:[],
	  actTab:1,
	  robotVisible:false,
	  maxScore:0,
	  myScore:0,
	  robotText:"",
	  robotVoice:"",
	  voiceExplain:"",
	  voiceExplainAudioUrl:"",
	  isRobotPlay:false,
	  isPause:false,
    };
  },
  computed: {
      timeString: function() {
        const minutes = Math.floor(this.totalSeconds / 60);
        const seconds = this.totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      },
	  progressWidth() {
		  let div = document.getElementsByClassName("list-img")[0];
		  console.log("Dsf",div)
		  return div.clientWidth
	  },
  },
  created() {},
  async mounted() {
    if (window.innerWidth < window.innerHeight) {
      document.body.style.transformOrigin = 'top left';
      document.body.style.transform = 'rotate(90deg) translateY(-100%)';
      document.body.style.width = '100vh';
      document.body.style.height = '100vw';
  	 //  document.getElementById('hunan-paper-wrap').style.height = window.innerWidth + 'px';
    }

    window.addEventListener("beforeunload", (e) => {
      this.beforeunloadHandler(e);
    });

    this.bPdfImagesAvailable = this.res.data.questions_pdf_images && this.res.data.questions_pdf_images.length>0;
//      && [32, 40, 132].includes(Number(this.$route.query.user_id));
//    console.log('this.$route.query.user_id', this.$route.query.user_id);
//    console.log('bPdfImagesAvailable ======', this.bPdfImagesAvailable);

    if(this.bPdfImagesAvailable) {
      await this.paperList(false);
    }
    else {
      if (window.AdobeDC) {
        await this.paperList(true);
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          this.paperList(true);
        });
      }
    }

    //microphone
    let thiz = this;
    if (!thiz.audioRecorder) {
      await new Promise(resolve => {
        let config = {sampleBits: 16, sampleRate: 16000, format: 'wav'};
        window.HZRecorder.get((recorder) => {
          thiz.audioRecorder = recorder;
          resolve();
        }, config);
      });
    }
  },

  destroyed() {
    this.clearCountdown();
    // this.timeEnd(1);
  },

  methods: {
    // 获取试卷
    async paperList(need) {
      const res = this.res;
      if (res.code == 0) {
        console.log(res);
        this.paperData.title = res.data.title;
        this.paperData.questions_pdf = res.data.questions_pdf;
        this.paperData.examinee_id = res.data.examinee_id;
        this.paperData.paper_id = res.data.paper_id;
        this.paperData.analysis_pdf = res.data.analysis_pdf;
        this.max_time = res.data.max_time;
        this.stateType = res.data.status;
        if(this.stateType=='done'){
          this.totalSeconds = 0;
        }else{
			this.totalSeconds = res.data.max_time*60;
		}
        this.paperData.user_score = res.data.user_score;
		    this.paperData.total_score = res.data.total_score;
        const obj = res.data.question_types.find(
          (item) => item.type_code.indexOf('listening')==0
        );
        if (obj) {
          this.paperData.audioList = obj.music_mp3;
        }

        if (this.stateType == "done") {
          this.btnText = "总分";
          this.$store.commit("setExamination", true);
        }

        const times = new Date();
        this.paperData.max_time = new Date(
          times.getTime() + this.max_time * 60 * 1000
        );

        this.dataList = res.data.question_types;
        this.handleCheck(
          this.dataList[0]
        );

        if (need) {
          this.showPDF("adobe-dc-view", this.paperData.questions_pdf);
          !this.$store.state.examination &&
            this.showPDF("adobe-dc2-view", this.paperData.questions_pdf);
        }

      } else {
        this.$message.error("试卷获取失败 "+(res.msg||''));
      }
    },
	startAnimation(item) {
		clearInterval(this.interval);
		  let totalTime = 60; // 默认倒计时时间为60秒
		  item.percentage = 100;
		  // 根据item.label值设置倒计时时间
		  if (item.label === 'read') {
			totalTime = 120;
		  } else if (item.label === 'answer') {
			totalTime = 60;
		  } else if (item.label === 'express') {
			totalTime = 180;
		  }

		  let time = totalTime;

		  this.interval = setInterval(() => {
			if (time > 0) {
			  time--;
			  item.percentage = (time / totalTime) * 100;
			} else {
			  this.stopVoice();
			}
		  }, 1000);
    },
	startVoice_bak(item){
		console.dir(item)
		this.actVoice = item.id;
		this.startAnimation(item);
		navigator.mediaDevices.getUserMedia({ audio: true })
			.then(stream => {
				mediaRecorder = new MediaRecorder(stream);
				mediaRecorder.start();

				mediaRecorder.addEventListener('dataavailable', event => {
					audioChunks.push(event.data);
				});

				mediaRecorder.addEventListener('stop', () => {
					const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
          console.dir(audioBlob)
          const audioFile = new File([audioBlob], "recording.wav", { type: 'audio/wav' });  // Blob转File
					this.uploadVoice(audioFile);
					audioChunks = [];
				});

				console.log("录音开始");
			})
			.catch(error => {
				console.error("录音失败:", error);
			});
	},
  startVoice(item) {
    console.dir(item)
    this.actVoice = item.id;
    if (this.audioRecorder) {
      this.startAnimation(item);
      this.audioRecorder.start();
      this.audioRecording = true;
    }
  },
	stopVoice_bak(){
		if(mediaRecorder){
			mediaRecorder.stop();
			console.log("录音停止");
			clearInterval(this.interval);
			console.dir(this.questionsObjext.questions);
		}
	},
  stopVoice(){
    if(this.audioRecorder && this.audioRecording){
      this.audioRecorder.stop();
      this.audioRecording = false;
      console.log("录音停止");
      clearInterval(this.interval);
      console.dir(this.questionsObjext.questions);
      let blob = this.audioRecorder.getWavBlob();
      let file = new window.File([blob], "record.wav");
      this.uploadVoice(file);
    }
  },
	uploadVoice(audioFile) {
    console.dir(audioFile)
    const formData = new FormData();
    signForm(formData);
    formData.append("examinee_id", Number(this.paperData.examinee_id));
    formData.append("paper_id", Number(this.paperData.paper_id));
    formData.append("question_id", this.actVoice);
    formData.append("my_answer", audioFile, "record.wav");
    fetch('https://readapi.bluebirdabc.com/exam/en/postExamineeAudio', {
        method: 'POST',
        body: formData
    })
    .then(response => response.json())
    .then(data => {
      this.uploadIdArr.push(this.actVoice);
      this.answers.push({
        question_id:this.actVoice,
        my_answer:""
      })
      this.actVoice = "";
      console.log('文件上传成功:', data);
    })
    .catch(error => {
      this.actVoice = "";
      console.error('上传失败:', error);
    });
	},

	startTest(){
		this.toggleStart = true;
		this.startCountdown();
		if(this.dataList[0].music_mp3){
			this.playAudio(this.dataList[0].music_mp3[0]);
		}
	},
	clickPlay(item){
		item.isPlay = true;
		this.$forceUpdate()
		this.playAudio(item.voice_url);
	},
	clickStop(item){
		item.isPlay = false;
		this.$forceUpdate()
		const player = this.$refs.audioPlayer;
		if (player) {
			player.pause();  // 暂停音频
			player.currentTime = 0;  // 将音频的当前时间设置为0
		}
	},
	playAudio(audio) {
	  // 获取音频元素引用并播放
	  this.$refs.audioPlayer.src = audio;
	  const player = this.$refs.audioPlayer;
	  if (player) {
		player.play().catch(error => console.error("Audio play failed", error));
	  }
	},
	tabClick(i){
		this.actTab = i;
		this.isRobotPlay = true;
		if(i==1){
			this.playAudio(this.robotVoice);
		}else if(i==2){
			this.playAudio(this.voiceExplainAudioUrl);
		}
	},
	handleAudioEnded() {
		if(this.questionsObjext&&this.questionsObjext.questions){
			for(let item of this.questionsObjext.questions){
				item.isPlay = false;
			}
		}
		this.$forceUpdate()
	  console.log("Audio ended. Handling events...");
	},
	selectItem(val,id,my_answer){
		if(this.stateType=='done'){
			return
		}
		if(val == my_answer){//点击重复的
			return
		}
		let t = 0;
		for(let item of this.answers){
			if(item.question_id == id){//老题
				item.my_answer = val;//覆盖老答案
				t = 1;
			}
		}
		if(t==0){//新题
			this.answers.push({
				question_id:id,
				my_answer:val
			})
		}
		this.setAct();
		console.dir(this.answers);
	},
	setAct(){//选色
		for(let item of this.questionsObjext.questions){
			for(let subItem of this.answers){
				if(item.id == subItem.question_id){
					item.my_answer = subItem.my_answer
				}
			}
		}
	},
	checkTit(item){
		this.robotVisible = true;
		this.maxScore = item.score;
		this.myScore = item.my_score;
		this.robotText = item.explain;
		this.robotVoice = item.explainAudioUrl;
		this.voiceExplain = item.voice_explain;
		this.voiceExplainAudioUrl = item.voice_explainAudioUrl;
		this.isRobotPlay = true;
		this.isPause = false;
		if(item.voice_explainAudioUrl){
			this.actTab = 2;
			this.playAudio(item.voice_explainAudioUrl);
		}else{
			this.actTab = 1;
			this.playAudio(item.explainAudioUrl);
		}
	},
	robotPlay(){
		this.isRobotPlay = true;
		if(this.isPause){
			const player = this.$refs.audioPlayer;
			player.play()
		}else{
			if(this.actTab == 2){
				this.playAudio(this.voiceExplainAudioUrl);
			}else{
				this.playAudio(this.robotVoice);
			}
		}
	},
	robotPause(){
		this.isRobotPlay = false;
		this.isPause = true;
		const player = this.$refs.audioPlayer;
		if (player) {
			player.pause();  // 暂停音频
		}
	},
	closeRobot(){
		this.isRobotPlay = false;
		this.isPause = false;
		const player = this.$refs.audioPlayer;
		if (player) {
			player.pause();  // 暂停音频
			player.currentTime = 0;  // 将音频的当前时间设置为0
		}
	},
	setOptional(item){
		return item.split(" ");
	},
	startCountdown: function() {
	  this.timer = setInterval(() => {
		if (this.totalSeconds > 0) {
		  this.totalSeconds -= 1;
		} else {
		  this.clearCountdown();
		  this.timeEnd();
		}
	  }, 1000);
	},
	clearCountdown: function() {
	  clearInterval(this.timer);
	  clearInterval(this.interval);
	  this.timer = null;
	  this.totalSeconds = 0;
	},
    // pdf展示
    showPDF(id, url) {
      console.log(id, url);
      const adobeDCView = new AdobeDC.View({
        clientId: "81211a7dfbe741499e88a71fbe4c8c48",
        divId: id,
      });
      adobeDCView.previewFile({
        content: {
          location: {
            url: url,
          },
        },
        metaData: { fileName: "北京奇峰天下科技有限公司技术支持.pdf" },
      });
    },

    // 点击题型获取题目
    async handleCheck(item) {
		this.stopVoice();
		console.log("ddddddddd",item)
	  this.type_code = item.type_code;
      if (this.activeIndex === item.type_code && !this.$store.state.examination) return;
      if (this.canNext) {
        this.loading = true;
        this.activeIndex = item.type_code;
        const res = await getQuestions({
          user_id: Number(this.$route.query.user_id), //用户id, //用户id
          pid: this.$route.query.pid, //合作方id
          examinee_id: Number(this.paperData.examinee_id), //考试场次id
          paper_id: Number(this.paperData.paper_id), //试卷id
          type_code: item.type_code, //待获取的题型代码
        });
        this.questionsObjext = res.data;
		this.first_title = res.data.questions[0].first_title;
		this.second_title = res.data.questions[0].second_title;
        //填空题学生答案的临时缓存
        if(this.questionsObjext) {
          for(let quest of this.questionsObjext.questions) {
            if((this.questionsObjext.type_code == 'writing-writing'||this.questionsObjext.type_code == 'writing-complete'||this.questionsObjext.type_code == 'writing-answer'||quest.label === 'text') && !this.textQuestionValues[quest.id]) {
              this.textQuestionValues[quest.id] = quest.my_answer||'';
            }
          }
        }

        //
        this.setAct();
        this.loading = false;
      } else {
        this.$message.warning("请先结束上一题作答");
      }
    },

    // 获取/修改 每大题答题时间
    handleEndTime(val) {
      console.log(val);
      const i = this.types_elapsed.findIndex(
        (item) => item.type_code === val.type_code
      );
      if (i >= 0) {
        this.types_elapsed.splice(i, 1);
      }
      this.types_elapsed.push(val);
    },

    // 获取/修改 每道题目的答案
    changeContents(val) {
      val.forEach((item) => {
        const i = this.answers.findIndex(
          (items) => items.question_id === item.question_id
        );
        if (i >= 0) {
          this.answers[i].my_answer = item.my_answer;
        } else {
          this.answers.push(item);
        }
      });
      console.log(this.answers);
    },
	//获取报告
	async openBaogao(){
		this.baogaoVisible = true;
		const res = await getExamineeReport({
		  user_id: Number(this.$route.query.user_id), //用户id, //用户id
		  pid: this.$route.query.pid, //合作方id
		  examinee_id_list: [Number(this.paperData.examinee_id)], //考试场次id
		});
		this.baogaoData = res.data
		const abilitiesObject = this.baogaoData.abilities.reduce((acc, current) => {
		  acc[current.ability] = current;
		  return acc;
		}, {});
		this.baogaoData.abilities = abilitiesObject;
		console.dir(this.baogaoData)
	},
	calc(num1,num2){
		return parseInt(num1/num2*100)
	},
    // 提交试卷
    handleSubmit() {
		this.$dialog.alert({
		  showCancelButton:true,
		  message: '是否确认提交试卷',
		})
	  .then(() => {
		const player = this.$refs.audioPlayer;
		console.dir(player)
		if (player) {
			player.pause()
		}
		console.dir(33333333)
		this.clearCountdown();
		this.timeEnd();
	  })
	  .catch(() => {
		// on cancel
	  })
    },
    async timeEnd(t) {
      this.ScantronLoading = true;
	 //  const loading = this.$loading({
		// lock: true,
		// text: '正在拼命生成卷子解析',
		// spinner: 'el-icon-loading',
		// background: 'rgba(0, 0, 0, 0.7)'
	 //  });
      // 合并填空题的答案
      for(let k in this.textQuestionValues) {
        this.answers.push({
          question_id:k,
          my_answer:this.textQuestionValues[k]
        })
      }
      // 提交试卷请求
      const res = await postExaminee({
        user_id: Number(this.$route.query.user_id), //用户id, //用户id
        pid: this.$route.query.pid, //合作方id
        examinee_id: Number(this.paperData.examinee_id), //考试场次id
        paper_id: Number(this.paperData.paper_id), //试卷id
        answers: this.answers,
        types_elapsed: this.types_elapsed,
		    type:t||''
      });
      if (res.code == 0) {
        this.ScantronLoading = false;
        // this.btnText = "总分";
        // this.$store.commit("setExamination", true);
        // this.paperData.user_score = res.data.total_score.type_score;
        // this.handleCheck(
        //   this.dataList[0].type_code
        // );
        // this.showPDF("adobe-dc-view", this.paperData.analysis_pdf);
//        window.location.reload();
        let p = 'examinee_id='+this.paperData.examinee_id;
        let url = window.location.href.replace('examinee_id=0', p);
        window.location.href = url;
        window.location.reload();
      } else {
        this.ScantronLoading = false;
        this.$message.error("提交失败请重新提交");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.child-wrap{
	display: flex;
	height: 100%;
	position: relative;
	.flex-1{
		flex: 1;
	}
  .flex-2{
    flex: 4;
    padding-left: 5%;
    padding-right: 5%;
  }
	.modal{
		position: absolute;
		z-index: 10;
		transform: translateZ(10px);
		width: 100%;
		height: 100%;
		background-color: rgba(115, 149, 221, .5);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
		.start{
			position: absolute;
			top:10%;
			cursor: pointer;
		}
	}
	.modals{
		background-color: rgba(0, 0, 0, .3);
	}
	#adobe-dc-view{
		height: 100%;
	}
	.test-paper{
		height: 100%;
		flex: 3;
		overflow: auto;
	}
	.banner{
//		width:878px;
//		height:234px;
		width:100%;
		height:257px;
		background-color: #FAFFF9;
		position: relative;
		.banner-title{
			width: 266px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 20px;
		}
		.banner-timer{
			width: 82px;
			position: absolute;
			right: 100px;
			top: 80px;
		}
		.time-cont{
			background-color: #000;
			color: white;
			font-size: 32px;
			font-weight: bold;
			width:174px;
			height: 60px;
			line-height: 60px;
			text-align: center;
			position: absolute;
			right: 40px;
			bottom: 80px;
		}
		.start-img{
			position: absolute;
			right: 40px;
			bottom: 10px;
			cursor: pointer;
			width:174px;
			height: 60px;
		}
		.banner-text{
			position: absolute;
			left: 17%;
			top:50%;
			div{
				font-size: 24px;
			}
			ul{
				font-size: 22px;
				width: 580px;
				max-height: 100px;
				overflow-y: auto;
				.banner-cir-div{
					display: flex;
					align-items: center;
				}
			}
		}
	}
	.score-cont{
		display: flex;
		justify-content: center;
		background-color: #FAFFF9;
		.score{
		  margin:0 10px;
		  position: relative;
		  width: 320px;
		  height: 91px;
		  .score-img{
		    position:absolute;
		    left:0;
			height: 91px;
		  }
		  .score-text{
		    color:black;
		    font-size:2vw;
		    font-weight:bolder;
		    position:absolute;
		    right:30px;
		    bottom:15px;
		  }
		}
	}
	.baogao-cont{
		display: flex;
		justify-content: center;
		background-color: #FAFFF9;
		margin: 10px 0;
		img{
			width:228px;
			height: 65px;
			cursor: pointer;
		}
	}
	.paper-cont{
		display: flex;
		height: calc(100% - 234px);
		padding: 0 20px 0 60px;
		background-color: #FAFFF9;
		.paper-ul{
			width: 80px;
			.ul-item{
				background-image: url('../../assets/hunan-paper/hui-t.png');
				margin: 5px 0;
				width: 80px;
				height: 185px;
				background-size: 80px 185px;
				font-size: 30px;
				writing-mode: vertical-rl; /* 或者使用 vertical-lr */
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
			}
			.actUl{
				background-image: url('../../assets/hunan-paper/yellow-t.png');
			}
		}
		.paper-list{
			flex: 1;
			margin-left: 20px;
			padding-top: 10px;
			.list-item{
				margin-bottom: 50px;
				padding-right: 20px;
				.areaClass{
					.areaAnswer{
						border: 5px solid #000;
						border-radius: 5px;
						font-weight: 900;
						font-size: 18px;
						height: 300px;
						width: 100%;
						padding: 5px 10px;
						img{
							width: 40px;
							height: 40px;
						}
					}
					.areaInput{
						border: 5px solid #000;
						border-radius: 5px;
						font-weight: 900;
						font-size: 18px;
						height: 300px;
						width: 100%;
						padding: 5px 10px;
					}
					.hunanjx{
						border-radius: 10px;
						margin-top: 5px;
						background-color: #18605A;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #fff;
						font-size: 22px;
						width: 100px;
						height: 50px;
						padding: 0 20px;
						cursor: pointer;
						word-wrap: break-word;
					}
				}
				.tit-img{
					width:30%;
				}
				.abc{
					border: 5px solid #000;
					border-radius: 5px;
					display: flex;
					margin-top: -5px;
					.item-index{
						width: 140px;
						background-color: rgb(178, 221, 223);
					}
					.item-btn{
						border-right: 1px solid #000;
						padding: 2px 0 10px;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						font-weight: 900;
						font-size: 18px;
						position: relative;
						.pf{
							position: absolute;
							bottom:0px;
							right:0px;
							height:40px;
						}
					}
					.sel-btn{
						width: 30px;
						height: 30px;
						border-radius: 100%;
						cursor: pointer;
						border: 2px solid #000;
					}
					.actBtn{
						background-color: #000;
						color: #fff;
					}
					.tkIndex{
						padding: 10px 0;
					}
					.pf-cont{
						flex: 1;
						padding: 10px 0;
					}
					.tkInput{
						border: 0;
						padding: 10px 0;
					}
					.hunanjx{
						background-color: #18605A;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #fff;
						font-size: 22px;
						width: 44px;
						padding: 0 20px;
						cursor: pointer;
						word-wrap: break-word;
					}
				}
				.s60{
					display: flex;
					align-items: center;
					img{
						width: 100px;
						height: 100px;
						cursor: pointer;
						border-radius: 100%;
						box-sizing: border-box;
					}
					.voiceBtn{
						position: relative;
						width: 100px;
						height: 100px;
						.bor{
							position: absolute;
						}
						.actimg{
							position: absolute;
							z-index: 1;
							width: 90px;
							height: 90px;
							left: 5px;
							top:5px;
						}
					}
					.s60-txt{
						background-color: #fff;
						height: 90px;
						overflow: auto;
						border: 5px solid #000;
						padding: 5px 10px;
						border-radius: 8px 0 0 8px;
						margin-left: 20px;
					}
					@media screen and (min-width: 0px) and (max-width: 540px) {
						img{
							width: 30px;
							height: 30px;
						}
						.voiceBtn{
							width: 30px;
							height: 30px;
							.actimg{
								width: 24px;
								height: 24px;
								left: 3px;
								top:3px;
							}
						}
						.s60-txt{
							height: 30px;
							padding: 3px 8px;
							border-radius: 4px 0 0 4px;
							margin-left: 10px;
							font-size: 12px;
						}
					}
					@media screen and (min-width: 541px) and (max-width: 900px) {
						img{
							width: 50px;
							height: 50px;
						}
						.voiceBtn{
							width: 50px;
							height: 50px;
							.actimg{
								width: 42px;
								height: 42px;
								left: 4px;
								top:4px;
							}
						}
						.s60-txt{
							height: 50px;
							padding: 3px 8px;
							border-radius: 4px 0 0 4px;
							margin-left: 13px;
							font-size: 13px;
						}
					}
					.checkImg{
						border: 5px solid #FFBE00;
					}
					.hunanjx{
						background-color: #18605A;
						height: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #fff;
						font-size: 22px;
						width: 44px;
						padding: 0 20px;
						cursor: pointer;
						border: 5px solid #000;
						border-left: 0;
						word-wrap: break-word;
						border-radius: 0 8px 8px 0;
					}
				}
			}
		}
	}
	.cur{
		cursor: pointer;
	}
	.robot-wrap{
		display: flex;
		align-items: flex-end;
		.img-cont{
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-right:20px;
		}
		.play,.stop,.pause{
			width: 100px;
			height: 100px;
			margin-bottom: 10px;
			cursor: pointer;
		}
		.robot-img{
			width:182px;
			height:258px;
			@media screen and (min-width: 0px) and (max-width: 540px) {
				width:10vw;
				height:14vw;
				margin-right:3vw;
			}
		}
		.robot-text0{
			background-image: url('../../assets/hunan-paper/area.png');
			width:1113px;
			height:308px;
			padding: 33px 35px 50px 35px;
			box-sizing: border-box;
			background-size: 100% 100%;
		}
		.robot-text{
		  background-image: url('../../assets/hunan-paper/area2.png');
		  width:1108px;
		  height:624px;
		  padding: 33px 35px 50px 35px;
		  box-sizing: border-box;
		  position: relative;
		  background-size: 100% 100%;
		  @media screen and (min-width: 0px) and (max-width: 540px) {
		  	flex:1;
			height: 50vw;
			padding: 5vw 5vw 7vw 5vw;
		  }
		  .btn-group{
		  	display: flex;
			position: absolute;
			top: -40px;
			width: calc(100% - 45px);
			.tab-btn{
				width: 120px;
				height: 40px;
				border-radius: 5px 5px 0 0;
				margin-left: 5px;
				background-color: #AFABAB;
				color: #fff;
				font-weight: bold;
				font-size:18px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
			.act-tab{
				background-color: #93CD70;
			}
			.score-btn{
				height: 30px;
				border-radius: 5px 5px 0 0;
				margin-left: auto;
				margin-right: 10px;
				background-color: #FFB929;
				color: #fff;
				font-size:20px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: bold;
				padding: 5px 20px;
				span{
					margin: 3px 10px 0;
				}
			}
		  }
		}
	}
	.baogao-wrap{
		width:60vh;
		height:90vh;
		background-size: 100% 100%;
		position: relative;
		font-size: 1.5vh;
		color: #000;
		div{
			width: 100%;
			position: absolute;
			text-align: left;
			left: 8vh;
		}
		ul{
			overflow-y: auto;
			position: absolute;
			list-style-type: circle;
			width: 45vh;
			left: 8vh;
			li{
				margin: 10px;
			}
		}
		.wel1{
			top: 31vh;
			left: 0;
			text-align: center;
		}
		.wel2{
			top: 33.7vh;
			left: 0;
			text-align: center;
		}
	}
	.Listening-bg{
		background-image: url('../../assets/hunan-home/listening-UI.png');
		ul{
			height: 12vh;
			top:65vh;
		}
		.ans1{
			top: 40vh;
		}
		.ans2{
			top: 46vh;
		}
		.ans3{
			top: 52vh;
		}
		.ans4{
			top: 57.6vh;
		}
		.end1{
			top: 78vh;
			font-weight: bold;
			text-align: left;
		}
	}
	.ReadingWriting-bg{
		background-image: url('../../assets/hunan-home/Reading & Writing-UI.png');
		ul{
			height: 12vh;
			top:69vh;
		}
		.ans1{
			display: none;
		}
		.ans2{
			top: 44vh;
		}
		.ans3{
			top: 52.5vh;
		}
		.ans4{
			top: 60.5vh;
		}
		.level1{
			display: none;
		}
		.end1{
			top: 81vh;
			font-weight: bold;
			text-align: left;
		}
	}
	.UseOfEnglish-bg{
		background-image: url('../../assets/hunan-home/Use of English-UI.png');
		ul{
			height: 12vh;
			top:66vh;
		}
		.ans1{
			top: 40vh;
			display: none;
		}
		.ans2{
			top: 46vh;
		}
		.ans3{
			top: 52vh;
			display: none;
		}
		.ans4{
			top: 57.6vh;
		}
		.level1{
			display: none;
		}
		.level3{
			display: none;
		}
		.end1{
			top: 78vh;
			font-weight: bold;
			text-align: left;
		}
	}
}
.circle{
	background-color: #000;
	width: 20px;
	height: 20px;
	border-radius: 100%;
	margin-left: 10px;
}
.my-textarea {
  border-style:none;
  background-color:#00000000;
  outline:none;
  resize:none;
  font-size: 20px
}
.my-textarea::-webkit-scrollbar{
  width:5px;
}
/* 添加滑块样式 */
.my-textarea::-webkit-scrollbar-thumb {
  background-color: #8c8c8c;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  border-radius: 3px;
}

/* 添加角度样式 */
.my-textarea::-webkit-scrollbar-corner {
  background-color: #f5f5f5;
}
/* 添加轨道样式 */
#explainBox::-webkit-scrollbar-track {
  background-color: #e4e4e3;
  border-radius: 3px;
}
#explainBox::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 3px;
}
::v-deep .robot-window{
	@media screen and (min-width: 0px) and (max-width: 540px) {
		margin-top:20px!important;
	}
}
::v-deep .baogao-window{
	margin-top: 20px!important;
	.el-dialog__body{
		padding: 0;
	}
}
</style>
