import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import Vant, { Dialog } from 'vant';
import 'vant/lib/index.css';
import './utils/dialog-directive'
// import './utils/viewer'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vant); // 全局使用 Vant
Vue.prototype.$dialog = Dialog; // 将 Dialog 注册到 Vue 的原型上，确保可以通过 this.$dialog 使用
// import VueQuillEditor from 'vue-quill-editor';
// // require styles 引入样式
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// Vue.use(VueQuillEditor)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
