import axios from "@/utils/request";  //导入封装请求的js文件
import sha1 from 'sha1'

function generateRandomString(length) {
    let result = '';
    const charactersLength = length;

    for (let i = 0; i < length; i++) {
        const randomValue = Math.floor((Date.now() * Math.random()) % charactersLength);
        result += String.fromCharCode(randomValue + 65);
    }

    return result;
}

function sign(data) {
    let user_id = localStorage.getItem('exam.user_id');
    let pid     = localStorage.getItem('exam.pid');
    let token   = localStorage.getItem('exam.token');

    //清大智汇 夺分派智能阅读 暂时不做登录验证
    if(pid === 'w7398uoaiIAF98wu') {
        data.user_id = user_id;
        data.pid = pid;
        return true;
    }

    if(! (user_id && pid && token)) {
        return false;
    }

    let num = '12345678';
    let uid = ''+user_id; //stringA
    let key = 'read.bluebirdabc.com-web-api';
    let i = 0;
    while (uid.length < 8) {
        uid += num[i];
        i++;
    }
    let randomStr = generateRandomString(12);
    let timestamp = ''+parseInt(Date.now()/1000);

    let toSign = uid + token + uid + key;// + randomStr + timestamp;
    let sign = sha1(toSign);

    data.user_id = user_id;
    data.pid     = pid;
    data.token   = token;
    data.sign    = sign;
    data.rand    = randomStr;
    data.timestamp = timestamp;

    return true;
}

export function signForm(formData) {
    let user_id = localStorage.getItem('exam.user_id');
    let pid     = localStorage.getItem('exam.pid');
    let token   = localStorage.getItem('exam.token');

    //清大智汇 夺分派智能阅读 暂时不做登录验证
    if(pid === 'w7398uoaiIAF98wu') {
        formData.append("user_id", Number(user_id));
        formData.append("pid", pid);
        return true;
    }

    if(! (user_id && pid && token)) {
        return false;
    }

    let num = '12345678';
    let uid = ''+user_id; //stringA
    let key = 'read.bluebirdabc.com-web-api';
    let i = 0;
    while (uid.length < 8) {
        uid += num[i];
        i++;
    }
    let randomStr = generateRandomString(12);
    let timestamp = ''+parseInt(Date.now()/1000);

    let toSign = uid + token + uid + key;// + randomStr + timestamp;
    let sign = sha1(toSign);

    formData.append("user_id", Number(user_id));
    formData.append("pid", pid);
    formData.append("token", token);
    formData.append("sign", sign);
    formData.append("rand", randomStr);
    formData.append("timestamp", timestamp);

    return true;
}


function checkIfNeedRelogin(code) {
    return [
        1001, //userId为空
        1002, //token为空
        1003, //签名为空
        1004, //userId不存在
        1005, //token已失效
        1006, //签名错误
    ].includes(code);
}

async function invokeWebApi(url, data) {
    let bSign = sign(data);
    if(!bSign) {
        console.log('sign false');
        toLogin(data.pid);
        return null;
    }

    let res = await axios.post(url, data);

    if(checkIfNeedRelogin(res.code)) {
        console.log(res.msg);
        toLogin(data.pid);
        return null;
    }

    if([
        131001,//HIPPO A1 从未购买过
        131002 //HIPPO A1 服务已过期
    ].includes(res.code)) {
        console.log(res.msg);
    }

    return res;
}

function toLogin(pid) {
    let e = document.createElement('div');
    e.style = `position:fixed;left:0%;top:0%;width:100%; height:100%;z-index:9999;`;
    e.innerHTML = `<div style="position:absolute;text-align:center;left:0%;top:10%;width:100%;height:80%;font-size:x-large;color:brown;">需要登录，即将跳转</div>`;
    document.body.appendChild(e);
    setTimeout(()=>{
        let backUrl = encodeURIComponent(window.location.href);
//      let loginUrl = 'https://read2.bluebirdabc.com/?pid='+pid+"&redirect="+backUrl;
//      window.location.href = loginUrl;
        let loginUrl = 'https://read2.bluebirdabc.com/my-login/#/?pid='+pid+"&redirect="+backUrl;
        window.open(loginUrl, '_self');
    }, 2000);
}

export function getPaperList(data) {
    return invokeWebApi('/getPaperList', data)
}

export function getPaperList2(data) {
    return invokeWebApi('/getPaperList2', data)
}

export function getPaper(data) {
    return invokeWebApi('/getPaper', data)
}
export function getQuestions(data) {
    return invokeWebApi('/getQuestions', data)
}

export function postExaminee(data) {
    return invokeWebApi('/postExaminee', data)
}

export function postExamineeAudio(data) {
    return invokeWebApi('/postExamineeAudio', data)
}

export function getExamineeReport(data) {
    return invokeWebApi('/getExamineeReport', data)
}

export function clearExaminees(data) {
    return invokeWebApi('/clearExaminees', data)
}
export function clearExaminees2(data) {
    return invokeWebApi('/clearExaminees2', data)
}

export function rechargeCardNumber(cardNumber) {
    return invokeWebApi('/rechargeCardNumber', {cardNumber: cardNumber});
}

export function getServieEndDate(serviceType) {
    return invokeWebApi('/getServieEndDate', {serviceType:serviceType});
}
export function getServieStatus(serviceType) {
    return invokeWebApi('/getServieStatus', {serviceType:serviceType});
}

export function enterKetOralTest(data) {
    return invokeWebApi('/enterKetOralTest', data)
}
export function continueKetOralTest(data) {
    return invokeWebApi('/continueKetOralTest', data)
}




export function invokeStreamWebApi(url, postData, runningFlag, onOneResult, onFinish) {
    let controller = new AbortController()
    const loadData = async () => {
        try {
            const response = await fetch(url, {
                method: 'post',
                headers: {
                    'Accept': 'text/event-stream;charset=UTF-8',
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(postData),
                signal: controller.signal
            })

            let content = '';
            const reader = (response).body.getReader()
            while (runningFlag()) {
                const {done, value} = await reader.read()

                if(value) {
                    let str = new TextDecoder().decode(value);
                    content += str;
                }

                while(runningFlag() && content.length > 0) {
                    let idx = content.indexOf("\n\n");
                    if(idx < 0)
                        break;

                    let one = content.substring(0, idx);
                    onOneResult(one);

                    content = content.substring(idx+2);
                }

                if (done) {
                    break
                }
            }
            if(runningFlag() && content.length > 0) {
                onOneResult(content);
            }
            onFinish();
        } catch (e) {
            console.log('请求失败', e);
            onFinish(e);
        }
    }

    return loadData();
}
export function getQuestionExplainStream(paper_id, examinee_id, question_id, onOneObject, onFinish) {
    let url = 'https://readapi.bluebirdabc.com/exam/en/getQuestionExplain';
    let postData = {
        paper_id: paper_id,
        examinee_id: examinee_id,
        question_id: question_id
    };
    sign(postData);

    let running = true;
    return invokeStreamWebApi(url, postData, ()=>{return running;}, (one) => {
        let oneObj = JSON.parse(one);
        if(checkIfNeedRelogin(oneObj.code)) {
            running = false;
            toLogin(postData.pid);
        } else {
            onOneObject(oneObj);
        }
    }, onFinish);
}
