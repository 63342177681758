// 秒转时分秒
export function getTime(time, type) {
    // 转换为式分秒
    let h = parseInt(time / 60 / 60 % 24)
    h = h < 10 ? '0' + h : h
    let m = parseInt(time / 60 % 60)
    m = m < 10 ? '0' + m : m
    let s = parseInt(time % 60)
    s = s < 10 ? '0' + s : s
    switch (type) {
        case 's':
            return s + '秒'
            break;
        case 'ms':
            return m + '分' + s + '秒'
            break;

        case 'hms':
            return h + '时' + m + '分' + s + '秒'
            break;

        default:
            return h + '时' + m + '分' + s + '秒'
            break;
    }
}

export function sleep(millis) {
    return new Promise(resolve=>setTimeout(resolve, millis));
}
