<template>
  <!-- 听力， -->
  <div class="hearing-box">
    <el-button v-if="!$store.state.examination" class="btn" type="primary" @click="handleClick">{{ btntext }}</el-button>
    <div class="score" v-if="$store.state.examination">
      <span>{{ value ? value.score : "" }}</span>
      <p>正确：{{ value ? value.right : "" }}题</p>
      <p>错误：{{ value ? value.error : "" }}题</p>
      <p>正确率：{{ value ? value.accuracy : "" }}%</p>
      <p>耗时：{{ value ? value.elapsed : "" }}分钟</p>
    </div>
    <div class="hearing">
	  <div class="list" v-for="(value, key) in arr">
		  <div class="second_title">{{key}}</div>
		  <div class="item" v-for="(item, i) in value" :key="i">
		    <span>{{ item.question_number }}</span>
		    <el-radio-group v-model="answerList[`${item.id}`]" :disabled="isStart">
		      <el-radio :label="items" :class="item.my_answer && $store.state.examination
		        ? item.my_answer == item.answer && item.my_answer === items
		          ? 'rights'
		          : item.my_answer === items
		            ? 'wrong'
		            : item.answer === items
		              ? 'rights'
		              : ''
		        : ''
		        " v-for="items in list(item)" :key="items">{{ items }}</el-radio>
		    </el-radio-group>
		  </div>
	  </div>
    </div>
    <audio :src="item" v-for="item in audioList" :key="item" ref="audios" style="display: none"></audio>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return { questions: [] };
      },
    },
    examinee_id: {
      type: [Number, String],
      default: 0,
    },
    audioList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      answerList: {},
      btntext: "开始答题",
      timeData: 0,
      timer: null,
      isStart: true,
    };
  },
  watch: {
    value: {
      handler(val, oldval) {
        if (this.examinee_id == 0) return;
        val.questions.forEach((item) => {
          if (item.my_answer) {
            this.answerList[item.id] = item.my_answer;
          }
        });
      },
      deep: true,
    },
    "$store.state.examination": {
      handler(val, oldval) {
        if (val) {
          for (let i = 0; i < this.audioList.length; i++) {
            this.$refs.audios && this.$refs.audios[i].pause();
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    arr() {
		if(this.value&&this.value.questions){
			var newObj = {};
			this.value.questions.forEach(function (obj) {
				var array = newObj[obj['second_title']] || [];
				array.push(obj);
				newObj[obj['second_title']] = array;
			});
			console.dir(newObj)
			return newObj
		}else{
			return []
		}
    },
  },
  mounted() {

  },
  methods: {
    list(item) {
      return item.optional.split(" ");
    },

    handleClick() {
      console.log('this.audioList.length 1=',this.audioList.length);
      for (let i = 0; i < this.audioList.length; i++) {
        console.log(this.$refs.audios[i].play().then());
        this.$refs.audios[i].play().then(() => {
//          this.$refs.audios[i].pause()
        })
      }
      this.$emit("next", !this.isStart);
      this.isStart = !this.isStart;
      if (!this.isStart) {
        if (!this.$store.state.isAudio) {
          this.$alert("听力即将开始，请确保已带上耳机", "提示", {
            confirmButtonText: "确定",
            showClose: false,
            callback: (action) => {
              let n = this.audioList.length;
              console.log('this.audioList.length 1=',n);
              for (let i = 0; i < n; i++) {
                var that = this;
                //settimeout采用闭包的形式，这样会依次执行，延迟时间设置为音乐的时长
                let time = 0

                for (let j = 0; j < i; j++) {
                  console.log(that.$refs.audios[j].duration * 1000, 'that.$refs.audios[j].duration * 1000');
                  time += that.$refs.audios[j].duration * 1000
                }
                console.log(time, 'time');

                setTimeout(
                  (function (i) {
                    return function timer() {
                      //多个audio循环ref取值用以下方法
                      that.$refs.audios[i].play()
                      console.log(i, '第几个音频在播放');
                    }
                  }(i)), time)

                // setTimeout(() => {
                //   that.$refs.audios[i].play();
                //   console.log(i, '第几个音频在播放');
                // }, time);
              }

              this.$store.commit("setAudioState", true);
            },
          });
        }

        this.timer = setInterval(() => {
          this.timeData++;
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.timer = null;

        const arr = Object.keys(this.answerList).map((key) => {
          return {
            question_id: Number(key),
            my_answer: this.answerList[key],
          };
        });
        console.log(arr);

        this.$emit("handleChange", arr);

        this.$emit("endTime", {
          type_code: this.value.type_code,
          elapsed: Math.round(this.timeData / 60),
        });
      }
      this.btntext = this.isStart ? "开始答题" : "结束答题";
    },
  },
};
</script>

<style lang="less" scoped>
.hearing-box {
  position: relative;
  padding: 5px 20px 20px;
  border: 2px solid #ccc;
  height: 92%;
  width: 94%;
  border-radius: 20px;
  overflow: auto;
  .btn {
    margin: 10px 0;
  }
}

.hearing {
  height: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    span {
      display: inline-block;
      width: 30px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin-right: 10px;
    }

    ::v-deep .el-radio {
      margin-right: 15px;
      // .el-radio__label {
      //   font-size: 20px !important;
      // }
      // .el-radio__inner {
      //   width: 20px;
      //   height: 20px;
      // }
    }
  }
}

.wrong {
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background: url("../../assets/错.png") no-repeat;
    background-size: 100% 100%;
  }
}

.rights {
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background: url("../../assets/对.png") no-repeat;
    background-size: 100% 100%;
  }
}

.score {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 220px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  z-index: 10;
  background: #fff;

  span {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 50px;
    font-style: italic;
  }

  p {
    font-size: 22px;
  }
}

@media (max-width: 640px) {
  .hearing {
    flex-direction: unset;
  }

  .hearing-box {
    height: 80%;
    overflow-y: scroll;
  }

  .hearing .item {
    width: 100%;
    align-items: center;
    margin-top: 10px;

    .el-radio {
      margin-right: 20px;

      ::v-deep .el-radio__label {
        display: inline-block;
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }

  .hearing .item span {
    width: 40px;
    font-size: 14px;
  }

  .wrong {
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      background: url("../../assets/错.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .rights {
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      background: url("../../assets/对.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .score {
    width: unset;
    padding: 10px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
.second_title{
	margin-left: 5px;
	margin-bottom: 10px;
	font-weight: bold;
}
</style>